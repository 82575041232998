<template>
  <div class="wrapper" :class="{'nav-open' : keycloak.idTokenParsed.preferred_username}">
    <Siderbar :keycloak="keycloak"/>
    <div class="main-panel" style="min-height: 100vh">
      <Navbar :keycloak="keycloak"/>
      <div class="content">
        <router-view></router-view>
      </div>
      <div class="close-layer visible" v-if="keycloak.idTokenParsed.preferred_username"></div>
    </div>
  </div>
</template>
<script>
import Siderbar from '@/components/layout/Sidebar'
import Navbar from '@/components/layout/Navbar'

export default {
  name: "Admin",
  props: ["keycloak"],
  data() {
    return {

    }
  },
  components: {
    Siderbar,
    Navbar
  }
}
</script>
