<template>
  <li class="nav-item cp" :class="{'active' : isActive}" v-if="data && data.CHILDREN">
    <a class="nav-link" :class="{'collapsed': show}" data-toggle="collapse" :aria-expanded="`${show}`" @click="toggle">
      <i :class="data.icon" v-if="data.icon"></i>
      <p>
        {{ data.code }}
        <b class="caret"></b>
      </p>
    </a>
    <div class="collapse" :class="{'show': show}">
      <ul class="nav">
        <template v-for="permission in $store.state.auth.menus">
          <router-link v-if="data.CHILDREN[permission]" tag="li" :to="data.CHILDREN[permission].link" class="nav-item" active-class="active" :key="data.CHILDREN[permission].name">
            <a class="nav-link">
              <span class="sidebar-normal">{{ data.CHILDREN[permission].code }}</span>
              <span class="sidebar-mini">{{ data.CHILDREN[permission].shortCode }}</span>
            </a>
          </router-link>
        </template>
      </ul>
    </div>
  </li>
  <router-link tag="li" :to="data.link" class="nav-item" active-class="active" v-else-if="data">
    <a class="nav-link">
      <i :class="data.icon" v-if="data.icon"></i>
      <p>{{ data.code }}</p>
    </a>
  </router-link>
</template>

<script>
export default {
  name: "SidebarMenu",
  props: {
    data: Object
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    isActive() {
      let contains = false;
      if (this.data && this.data.CHILDREN && this.data.CHILDREN.length > 0) {
        for (let i = 0; i < this.data.CHILDREN.length; i++) {
          if (this.data.CHILDREN[i].name && this.data.CHILDREN[i].name === this.$route.name) {
            contains = true;
          }
        }
      }
      return contains;
    }
  },
  methods: {
    toggle() {
      this.show = !this.show;
    }
  }
}
</script>

<style scoped>

</style>