<template>
  <nav class="navbar navbar-expand-lg navbar-fixed">
    <div class="container-fluid">
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav">
          <li class="nav-item dropdown" :class="{'show': userNavShow}">
            <a class="nav-link dropdown-toggle cp" @click="toggleUserNavigation" v-click-outside="hideUserNavigation">
              <i class="nc-icon nc-bullet-list-67"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right" :class="{'show': userNavShow}">
              <span class="dropdown-item cp" @click="goTo('/')">{{keycloak.idTokenParsed.preferred_username}}</span>
              <router-link class="dropdown-item cp" :to="'/check-key'" v-if="isExists">CHECK</router-link>
              <span class="dropdown-item cp" @click="logOut">Chiqish</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  props: ["keycloak"],
  data() {
    return {
      userNavShow: false
    }
  },
  computed: {
    isExists(){
      return this.$store.state.auth.menus.filter(value => value === "CHECK_KEY").length > 0
    }
  },
  methods: {
    logOut() {
      localStorage.clear()
      this.keycloak.logout()
    },
    toggleUserNavigation() {
      this.userNavShow = !this.userNavShow;
    },
    hideUserNavigation() {
      this.userNavShow = false;
    },
    goTo(url) {
      this.$router.push(url);
    }
  }
}
</script>
