import axios from 'axios';

export const BASE_API = process.env.VUE_APP_API_URL;

const http = axios.create({
  baseURL: BASE_API
});

http.interceptors.request.use(function (config) {
  const token = localStorage.getItem('vue-token');
  if (token != null && token.length > 0) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
}, function (err) {
  return Promise.reject(err)
});

export default http;
