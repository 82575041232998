import Vue from 'vue';
import Vuex from 'vuex';
import Keycloak from "keycloak-js";
import App from "./App";
import router from "./router";
import http from "@/axios";

export const KEYCLOAK = "KEYCLOAK";
export const APP = "APP";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        meta: {
            withoutToken: null,
            permissions: []
        },
        info: null,
        auth: {
            actions: [],
            menus: []
        }
    },
    mutations: {
        [KEYCLOAK]() {
            let initOptions = {
                url: process.env.VUE_APP_KEYCLOAK_API_URL + '/auth',
                realm: process.env.VUE_APP_KEYCLOAK_REALM,
                clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
                onLoad: 'login-required'
            }

            let keycloak = new Keycloak(initOptions);
            let login = {
                onLoad: initOptions.onLoad
            }

            if (this.state.meta.withoutToken){
                login = { checkLoginIframe: false }
            }

            keycloak.init(login).then((auth) => {
                if (auth){
                    localStorage.setItem("vue-token", keycloak.token)

                    let roles = keycloak.tokenParsed.realm_access.roles

                    if (keycloak.tokenParsed.resource_access[process.env.VUE_APP_KEYCLOAK_CLIENT_ID]){
                        roles = roles.concat(keycloak.tokenParsed.resource_access[process.env.VUE_APP_KEYCLOAK_CLIENT_ID].roles)
                    }

                    this.state.isAdmin = false;
                    this.state.isSigner = false;
                    if (roles && roles.length > 0) {
                        for (const role of roles) {
                            if (role === 'ROOT' || role === 'ADMIN' || role === 'DIRECTOR') {
                                this.state.isAdmin = true
                            }

                            if (role === 'SIGNER') {
                                this.state.isSigner = true
                            }
                        }
                    }

                    if (this.state.isAdmin || this.state.isSigner){
                        http.get('permission/user/menu')
                            .then(res => {
                                store.commit('setPermission', res.data.menus)
                            }, err => {
                                console.log(err)
                            })
                        new Vue({
                            render: h =>h(App, {props: {keycloak: keycloak}}),
                            router,
                            store
                        }).$mount('#app');
                    } else {
                        if (this.state.meta.withoutToken){
                            this.state.info = {type: 'PERMISSION_DENIED', msg: 'Sizning bu APP ni ishlatish uchun ruhsatingiz yo\'q.'}
                        }
                    }
                } else {
                    if (this.state.meta.withoutToken){
                        this.state.info = {type: 'GUEST'}
                    } else {
                        keycloak.login()
                    }
                }

                if (this.state.info !== null) {
                    new Vue({
                        render: h =>h(App),
                        router,
                        store
                    }).$mount('#app')
                }

                //Token Refresh
                setInterval(() => {
                    localStorage.clear()
                }, 12 * 3600 * 1000)

            }).catch(() => {
                console.error("Authenticated Failed");
            });
        },
        [APP]() {
            new Vue({
                render: h =>h(App),
                router,
                store
            }).$mount('#app');
        },
        initPermission(state, meta) {
            if (meta){
                state.meta = meta
                if (meta.permission && !meta.withoutToken) {
                    try {
                        http.post('permission/user/actions', {code: meta.permission}).then(response => {
                            if (response && response.data) {
                                state.auth.actions = response.data;
                            }
                        })
                    } catch (err) {
                        console.log(err)
                    }
                }
            }
        },
        setPermission(state, menus) {
            state.auth.menus = menus
        }
    },
    actions: {
        keycloak({commit}) {
            commit(KEYCLOAK)
        },
        app({commit}) {
            this.state.info = {type: 'GUEST'}
            commit(APP)
        }
    }
});

export default store;