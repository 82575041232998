<template>
  <div id="app">
    <Admin v-if="keycloak && keycloak.authenticated" :keycloak="keycloak"/>
    <Guest v-else/>
  </div>
</template>

<script>
import Admin from '@/views/Admin';
import Guest from '@/views/Guest';

export default {
  name: "App",
  props: ["keycloak"],
  components: {
    // Loading,
    Admin,
    Guest
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
