<template>
  <div class="content">
    <info v-if="$store.state.info.type !== 'GUEST'" :data="$store.state.info.msg"/>
    <router-view></router-view>
  </div>
</template>
<script>

import Info from "@/views/errors/info";
export default {
  name: "Guest",
  data() {
    return {

    }
  },
  components: {
    Info
  }
}
</script>
