import Vue from 'vue'
import axiosHttp from './axios'
import router from './router'
import ClickOutside from 'vue-click-outside';
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import VueSweetalert2 from 'vue-sweetalert2'
import vue2Dropzone from 'vue2-dropzone';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/css/light-bootstrap.css';
import '@/assets/css/flags.css';
import 'font-awesome/css/font-awesome.min.css';
import '@/assets/css/nucleo-icons.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import '@/assets/css/app.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import vSelect from 'vue-select';
import CurrencyFilter from '@/shared/currency';
import store from './store'

Vue.use(Vuelidate);
Vue.filter('currency', CurrencyFilter);
Vue.use(VueTheMask);
Vue.use(VueSweetalert2);
Vue.component('vue-dropzone', vue2Dropzone);
Vue.directive('click-outside', ClickOutside);
Vue.component('v-select', vSelect);
Vue.prototype.$eventBus = new Vue({router});
Vue.prototype.$http = axiosHttp;
Vue.prototype.hasAction = function (permission) {
    return this.$store.state.auth.actions.filter(value => value === permission).length > 0
};
Vue.config.productionTip = false;

if (localStorage.getItem('vue-token')){
    store.dispatch('keycloak').then()
}