<template>
  <div class="sidebar" data-color="azure">
    <div class="logo">
      <router-link tag="a" to="/" class="logo-tim">
        <img src="@/assets/images/logo.png" alt="Image alt"/>
      </router-link>
      <router-link tag="a" to="/" class="simple-text logo-normal" style="margin-left:20px">
        e-sign-admin.uz
      </router-link>
    </div>
    <div class="sidebar-wrapper">
      <ul class="nav">
        <SidebarMenu :data="MENU[permission]" v-for="permission in $store.state.auth.menus" :key="permission"/>
      </ul>
    </div>
  </div>
</template>

<script>
import SidebarMenu from "@/components/layout/SidebarMenu";
export default {
  name: "Sidebar",
  props: ["keycloak"],
  components: {SidebarMenu},
  data() {
    return {
      MENU: {
        USER: {
          code: 'Foydalanuvchi',
          icon: 'fa fa-user-circle',
          CHILDREN: {
            USER: {
              shortCode: 'F',
              code: 'Foydalanuvchi',
              name: 'user',
              link: '/user'
            }
          }
        },
        SIGN: {
          code: 'Imzo',
          icon: 'fa fa-pencil-square-o',
          link: '/sign'
        },
        KAFKA_EVENTS: {
          code: 'Kafka events',
          icon: 'fa fa-cubes',
          link: '/kafka-events'
        }
      }
    }
  }
}
</script>