<template>
  <div class="row">
    <div class="col-12 mt-5">
      <h3><b>{{data}} </b></h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "permission-denied",
  props:{
    data:{
      type: String
    }
  }
}
</script>

<style scoped>

</style>